import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PaymentConsent } from "@/types/quote-data";

const initialState = {
  type: null,
} as PaymentConsent;

const paymentConsents = createSlice({
  name: "paymentConsents",
  initialState,
  reducers: {
    setPaymentConsents: (state, action: PayloadAction<typeof initialState>) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { setPaymentConsents } = paymentConsents.actions;
export default paymentConsents.reducer;
