import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  isFilled: boolean;
} = { isFilled: false };

const contactDetailsSlice = createSlice({
  name: "contactDetails",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateContactDetails: (
      state,
      action: PayloadAction<typeof initialState>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
      return state;
    },
  },
});

export const { reset, updateContactDetails } = contactDetailsSlice.actions;
export default contactDetailsSlice.reducer;
