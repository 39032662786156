import { combineReducers } from "@reduxjs/toolkit";

import apiErrorSlice from "./slices/errors/api-error.slice";
import medicalReducer from "./slices/medical.slice";
import paymentConsentsSlice from "./slices/payment-consents.slice";
import progressSlice from "./slices/progress.slice";
import questionReducer from "./slices/question/question.slice";
import quoteReducer from "./slices/quote.slice";
import contactDetailsSlice from "./slices/contact-details.slice";

const rootReducer = combineReducers({
  quote: quoteReducer,
  question: questionReducer,
  medical: medicalReducer,
  progress: progressSlice,
  paymentConsents: paymentConsentsSlice,
  apiError: apiErrorSlice,
  contactDetails: contactDetailsSlice,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
